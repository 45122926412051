<template>
  <master-layout
    smallTitle="inizia"
    bigTitle="Servizio"
    titleStyle="horizontal"
  >
    <div class="page" style="padding-bottom: 100px; padding-top: 60px">
      <div class="hello ion-margin-bottom" style="min-height: 350px">
        <h5 style="text-align: center">
          Scatta una foto del luogo in cui ti trovi e conferma i dati per
          iniziare la rilevazione
        </h5>
        <div v-if="loadingPicture" class="icon">
          <ion-spinner name="crescent"></ion-spinner>
        </div>
        <div v-else class="icon">
          <ion-icon
            :icon="camera"
            @click="addFiles()"
            class="camera-icon"
          ></ion-icon>
        </div>
        <picture-input
          ref="pictureInput"
          :plain="true"
          width="300"
          height="300"
          accept="image/*"
          size="10"
          :crop="false"
          radius="3"
          :removable="true"
          button-class="btn"
          @change="onChangePicInput"
        >
        </picture-input>
      </div>
      <div
        v-if="loadingRilevazione"
        class="flex justify-content-center align-items-center"
      >
        <ion-spinner name="crescent"></ion-spinner>
      </div>
      <div v-else>
        <h5 style="font-weight: bold; text-align: center">Dati rilevati</h5>
        <div class="riga">
          <div class="col-icon">
            <ion-icon
              :icon="pin"
              style="font-size: 2rem"
              class="camera-icon"
            ></ion-icon>
          </div>
          <div class="col-text">
            <ion-item>
              <div v-if="loading">Caricamento in corso...</div>
              <ion-input
                v-else
                v-model="luogoInizio"
                inputmode="text"
                @ionFocus="setFocusTrue"
                @ionBlur="setFocusFalse"
                required
                placeholder="Scrivi il luogo di partenza"
              ></ion-input>
            </ion-item>
          </div>
        </div>
        <div class="riga">
          <div class="col-icon">
            <ion-icon
              :icon="time"
              style="font-size: 2rem"
              class="camera-icon"
            ></ion-icon>
          </div>
          <div class="col-text">
            <span style="margin-bottom: 3rem"
              >{{ new Date().toLocaleDateString("it") }} -
              {{
                new Date().toLocaleTimeString("it", {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              }}</span
            >
          </div>
        </div>
        <!-- <div>
        <ion-row class="ion-justify-content-center ion-margin-top">
          <ion-col size="auto">
            <ion-button @click="startOrdine">Inizia Rilevazione</ion-button>
          </ion-col>
        </ion-row>
      </div> -->
      </div>
      <button
        style="z-index: 1000"
        @click="startOrdine"
        class="fixed bottom center button-bottom-axerta-secondary w-75 text-16"
      >
        {{
          loadingRilevazione ? "Caricamento in corso..." : "Inizia Rilevazione"
        }}
      </button>
    </div>
    <div id="bottom-component" class="bottom-component">
      <div v-if="tastieraFocus"></div>
    </div>
  </master-layout>
</template>

<script>
import PictureInput from "vue-picture-input";
import {
  IonAvatar,
  IonLabel,
  IonSelectOption,
  IonRow,
  IonCol,
  IonCard,
  IonList,
  IonItem,
  IonInput,
  IonSelect,
  IonTextarea,
  IonIcon,
  IonButton,
  IonFab,
  IonFabButton,
  IonSpinner,
  modalController,
  //   IonRouterOutlet,
  loadingController,
  IonLoading,
} from "@ionic/vue";
// import { ref } from "vue";
import ApiService from "../../common/service.api";
import axios from "axios";
import { camera, pin, time } from "ionicons/icons";
// import { defineComponent } from "vue";
import AxertaChat from "../Modals/AxertaChat.vue";
import AxertaNewLog from "../Modals/AxertaNewLog.vue";
import MasterLayout from "../../components/MasterLayout";
import { mapGetters } from "vuex";
import { useRoute, useRouter } from "vue-router";

import EXIF from "exif-js";

export default {
  name: "KpiChat",
  components: {
    PictureInput,
    IonAvatar,
    IonLabel,
    IonSelectOption,
    IonRow,
    IonCol,
    IonCard,
    IonList,
    IonItem,
    IonInput,
    IonSelect,
    IonTextarea,
    IonIcon,
    IonButton,
    IonFab,
    IonFabButton,
    MasterLayout,
    IonLoading,
    IonSpinner,
  },

  data() {
    return {
      pin,
      time,
      camera,
      previewImgUrl: "",
      files: [],
      exifs: null,
      image: null,
      urlImage: "",
      luogoInizio: "",
      route: null,
      tastieraFocus: false,
      loading: false,
      loadingPicture: false,
      loadingRilevazione: false,
    };
  },
  created() {
    window.loadingCPresented = null;
    this.route = useRoute();
    this.router = useRouter();
    this.getUserLocation();
  },

  methods: {
    setFocusTrue() {
      this.tastieraFocus = true;
      this.scrollDown();
    },
    setFocusFalse() {
      this.tastieraFocus = false;
    },
    scrollDown() {
      document
        .querySelector("#bottom-component")
        .scrollIntoView({ behavior: "smooth" });
    },
    getUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log(
              "🚀 ~ file: ProveEmmanuele.vue:28 ~ locatorButtonPressed ~ position:",
              position.coords
            );

            this.getLocationFrom(
              position.coords.latitude,
              position.coords.longitude
            );
          },
          (error) => {
            console.log(
              "🚀 ~ file: ProveEmmanuele.vue:29 ~ locatorButtonPressed ~ error:",
              error.message
            );
          }
        );
      } else {
        console.warn(
          "Whappy ERR: Il tuo browser non supporta la GeoLocation API"
        );
      }
    },
    getLocationFrom(lat, long) {
      this.loading = true;
      axios
        .get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            lat +
            "," +
            long +
            "&key=" +
            process.env.VUE_APP_GOOGLE_MAPS_API
        )
        .then((res) => {
          if (res.data.error_message) {
            console.log(
              "🚀 ~ file: ProveEmmanuele.vue:41 ~ getLocationFrom ~ res.data.error_message:",
              res.data.error_message
            );
          } else {
            this.luogoInizio = res.data.results[0].formatted_address;
          }
        })
        .catch((err) => {
          console.log(
            "🚀 ~ file: ProveEmmanuele.vue:46 ~ getLocationFrom ~ err:",
            err
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onChangePicInput(image) {
      this.loadingPicture = true;

      // if (image) {
      //   console.log('Picture loaded.')
      //   this.image = image
      //   EXIF.getData(this.$refs.pictureInput.file, function () {
      //     console.log("🚀 ~ file: AxertaHome.vue:136 ~ this.$refs.pictureInput.file", this.$refs.pictureInput.file)
      //     console.log('image info', this)
      //     console.log('exif data', this.exifdata)
      //   })

      // } else {
      //   console.log('FileReader API not supported: use the <form>, Luke!')
      // }
      this.image = image;

      if (this.image.target) {
        const formData = new FormData();

        formData.append("file", this.image.target.files[0]);

        ApiService.post("Upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((res) => {
            this.urlImage = "/Drive/" + res.data;
          })
          .catch((err) => console.log(err))
          .finally(() => {
            this.loadingPicture = false;
          });
      }
    },
    startOrdine() {
      this.loadingRilevazione = true;
      ApiService.post(
        "DossierManager/StartRilevazione/" + this.route.params.idOrdine,
        {
          dataOraInizio: new Date(),
          luogoInizio: this.luogoInizio,
          fotoInizio: this.urlImage,
        }
      ).then((res) => this.addRilevazione(res));
    },

    addRilevazione(res) {
      ApiService.post("DossierManager/AddRilevazioneDettaglio/" + res.data, {
        descrizione: "INIZIO SERVIZIO",
        elementiMultimediali: [
          {
            urlElemento: "https://api-axerta.whappy.it" + this.urlImage,
          },
        ],
        luogo: this.luogoInizio,
        dataOra: new Date(),
        stato: "3",
        indagati: [],
      }).finally(() => {
        this.loadingRilevazione = false;
        this.router.push(
          "/axerta/descrizione-ordine/" + this.route.params.idOrdine
        );
      });
    },

    getExif(image) {
      let tags = EXIF.Tags;

      console.log("onChange!");
      if (image) {
        let vm = this;
        EXIF.getData(image, function () {
          console.log("image , ", image);
          console.log("exif data , ", this);
          vm.exifs = this;
        });
        console.log(
          "DeviceManufacturer",
          EXIF.getTag(this, "DeviceManufacturer")
        );

        EXIF.getData(image, function () {
          for (const [key, value] of Object.entries(tags)) {
            EXIF.getTag(this, value);

            console.log(value, EXIF.getTag(this, value));

            vm.files.push([value, EXIF.getTag(this, value)]);

            //   var makeAndModel = document.getElementById("makeAndModel");
            // makeAndModel.innerHTML = `${make} ${model}`;
          }
          // tags.forEach(tag => {
          //   console.log(tag, EXIF.getTag(this, tag))
          // })

          // var make = EXIF.getTag(this, "Make");
          // var model = EXIF.getTag(this, "Model");
        });
      } else {
        console.log(`it's not image`);
      }
    },
    addFiles() {
      this.$refs.files.click();
    },
  },
  computed: {
    ...mapGetters("loading", {
      isLoading: "isLoading",
    }),
  },
};
</script>

<style lang="scss" scoped>
// * {
//   padding: 0;
//   margin: 0;
//   // border: 1px solid rgb(150, 246, 150);
// }

.post-pic {
  display: grid;
  place-items: center;
  width: 100%;
}

.post-pic img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.camera-icon {
  /* margin-top: 3rem; */
  color: var(--ion-color-primary);
  font-size: 5rem;
}

input[type="file"] {
  position: absolute;
  top: -500px;
}

ion-row {
  width: 100%;
}

ion-fab-button {
  margin: 1rem;
}

ion-fab-button ion-icon {
  font-size: 2rem;
  color: white;
  margin: 0;
}

ion-icon {
  font-size: 1.5rem;
  color: rgb(141, 141, 141);
}

ion-list {
  background-color: rgba(255, 255, 255, 0);
}

.icon {
  display: grid;
  place-content: center;
}

.leds {
  margin: auto;
  display: flex;
  justify-content: center;
}

.led {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0.1rem;
  background: rgb(200, 200, 200);
}

.open {
  background: rgb(231, 36, 36);
}

.on-going {
  background: rgb(255, 169, 40);
}

.closed {
  background: rgb(45, 167, 34);
}

.item-description {
  padding-left: 1rem;
}

.categoria {
  font-size: 0.7rem;
  font-weight: bolder;
  color: var(--ion-color-secondary);
}

.margine {
  font-weight: bold;
  text-align: center;
}

.totale {
  padding: 0 1.5rem;
  z-index: 1;

  font-size: 1.1rem;

  span {
    font-weight: bold;
  }

  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.lista-contenitore {
  padding-bottom: 134px;
}

.warning-icon {
  padding-top: 2rem;

  ion-icon {
    font-size: 5rem;
  }

  .font-bold {
    font-weight: bold;
    color: var(--ion-color-secondary);
  }
}

.hello {
  position: relative;
}

.icon {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
}

.riga {
  display: flex;
  align-items: center;

  .col-icon {
    width: 10%;
  }

  .col-text {
    width: 80%;
  }
}

.page {
  padding-bottom: 100px;
}

.bottom-component {
  width: 100%;
  padding-top: 50vh;
}
</style>
